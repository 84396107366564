const pageContainer = document.querySelector('#container');

export default function () {
    const sentinel = document.querySelector('.return-to-top-sentinel');
    if (!pageContainer || !sentinel || window.innerWidth < 1279) return;
    if (pageContainer.offsetHeight < 2000) return;

    const returnButton = createReturnButton();
    trackScrollAmount();
    trackSentinel(sentinel, returnButton);

    returnButton.addEventListener("click", function () {
        returnButton.style.setProperty('--scrollProgress', `0%`);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    });
}

function createReturnButton() {
    const returnButton = document.createElement('button');
    returnButton.classList.add("return-to-top");
    returnButton.title = 'Wróć na górę strony';
    pageContainer.appendChild(returnButton);
    return returnButton;
}

function trackSentinel(sentinel, returnButton) {
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {

            if (entry.isIntersecting) {
                returnButton.classList.remove("show");
                return;
            }

            setTimeout(function () {
                returnButton.classList.add('show');
                returnButton.style.position = 'fixed';
                updateCssCircle();
            }, 150);

        });
    });
    observer.observe(sentinel);
}

function trackScrollAmount() {
    let debounceTimeout;

    window.addEventListener('scroll', function () {

        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(function () {
            updateCssCircle();
        }, 25);
    });
}

function updateCssCircle() {
    const scrollTop = window.scrollY;
    const pageHeight = document.documentElement.scrollHeight - window.innerHeight;
    const percentScrolled = (scrollTop / pageHeight) * 100;
    const returnButton = document.querySelector('.return-to-top');
    returnButton.style.setProperty('--scrollProgress', `${percentScrolled}%`);
}
