import { getScript } from './utils/utils';
export default function () {

    const players = document.querySelectorAll('.video-player-wrapper');

    if (players.length === 0)
        return;

    if (!window.Vimeo)
        getScript('https://player.vimeo.com/api/player.js', function () {
            players.forEach(function (item) {
                item.addEventListener('click', function () {
                    const url = item.getAttribute('data-url');

                    var player = new Vimeo.Player(item.id, {
                        url: url,
                        width: 960
                    });
                     
                    player.on("play", function (data) {

                        if (typeof exponea !== 'undefined') {
                            exponea.track('video_play', { video_name: player.element.title })

                        }
                    });

                    player.play();


                });
            });
        });


}